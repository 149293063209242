//Avoid typekit FOUT
html {
  background-color: $c-surface-light;

  --header-height: #{$header-height};
  --secondary-header-height: #{$secondary-header-height};

  @include mq($from: $header-breakpoint) {
    --header-height: #{$header-height-large};
    --secondary-header-height: #{$secondary-header-height-large};
  }

  &.js {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &.wf-active,
    &.wf-inactive,
    &.ready {
      opacity: 1;
    }
  }
}

body {
  --scrollbar-width: #{$scrollbar-width};
}

// SVG sprite
body > svg {
  display: none;
}

// RWD
img {
  max-width: 100%;
  height: auto;
}

// NO SCROLL
.no-scroll,
.onboarding {
  overflow: hidden;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  .app {
    position: relative;
    padding-right: var(--scrollbar-width);
    overflow: hidden;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
  }

  // Fix layout shift due to missing scrollbar
  header.header {
    transform: translateX(calc((var(--scrollbar-width) / 2) * -1));
  }

  .menu-opened {
    right: var(--scrollbar-width);
  }
}

// VARIOUS
hr {
  height: 1px;
  background-color: $c-gray-darker;
  border: 0;
}

.is-hidden {
  visibility: hidden;
}

a {
  color: currentColor;
}
