.wrapper,
[class*='wrapper--'] {
  width: 100%;
  max-width: $content-width;
  padding-right: col(1, 16);
  padding-left: col(1, 16);

  &[class*='--no-padding'] {
    padding-right: 0;
    padding-left: 0;
  }

  @include mq(m) {
    padding-right: $wrapper-padding;
    padding-left: $wrapper-padding;
  }

  @include mq(wrapper) {
    margin-right: auto;
    margin-left: auto;
  }
}
