/**
 * Global settings
 */
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// Remove tap highlight on iOS
* {
  -webkit-tap-highlight-color: $c-transparent; // sass-lint:disable-line no-vendor-prefixes
}

/**
 * Media
 */
img {
  height: auto;
}

/**
 * Forms
 */
:focus-visible {
  @extend %has-focus;
}

:focus:not(:focus-visible) {
  outline: none;
}

ul.reset,
ol.reset {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

button {
  padding: 0;
  color: currentColor;
  background-color: transparent;
  border-width: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &.link {
    text-decoration: underline;
  }
}
