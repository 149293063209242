
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










.onboarding {
  position: fixed;
  z-index: $z-index-header;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: $c-surface-light;
}

.onboarding__baseline {
  @include liquid('font-size', 25px, 92px);

  font-style: italic;
  opacity: 0;
}
