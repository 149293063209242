/* stylelint-disable at-rule-whitelist */

// @font-face {
//   font-family: 'Suisse Intl';
//   src: url('/assets/fonts/suisseintl-book.woff2') format('woff2'),
//     url('/assets/fonts/suisseintl-book.woff') format('woff');
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
// }

@font-face {
  font-family: 'Suisse Intl';
  src: url('/assets/fonts/suisseintl/SuisseIntl-Regular-WebS.woff2')
      format('woff2'),
    url('/assets/fonts/suisseintl/SuisseIntl-Regular-WebS.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('/assets/fonts/suisseintl/SuisseIntl-RegularItalic-WebS.woff2')
      format('woff2'),
    url('/assets/fonts/suisseintl/SuisseIntl-RegularItalic-WebS.woff')
      format('woff');
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Mazius;
  src: url('/assets/fonts/mazius/generated/mazius-extraitalic.woff2')
      format('woff2'),
    url('/assets/fonts/mazius/generated/mazius-extraitalic.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

html {
  font-size: $base-font-size;
}

body {
  color: $c-text-dark;
  font-family: #{$ff-default};
  font-size: $body-text;
  font-weight: 400;
  line-height: 1.4;
  -moz-osx-font-smoothing: grayscale; // Fix "bold-ish" font issue on retina display
  -webkit-font-smoothing: antialiased; // Fix "bold-ish" font issue on retina display

  @include mq(m) {
    font-size: $body-text-m;
    line-height: 1.58;
  }
}
