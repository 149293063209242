
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






































































.hero__subtitle {
  @include liquid(font-size, 24px, 36px);

  max-width: 40ch;
  margin: $hero-subtitle-margin-top auto;
  text-align: center;
}

.hero__link-container {
  @include liquid('margin-bottom', 40px, 80px);

  display: inline-block;
  width: 100%;
  text-align: center;
}

.hero__link {
  @include liquid(font-size, 24px, 36px);
}

$filter-bottom-margin: 2rem;

.hero__filters-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: $hero-subtitle-margin-top;
  margin-bottom: $filter-bottom-margin;
}

.hero__filters-list__item {
  margin: 0 1.9rem $filter-bottom-margin;
}

.input:focus {
  + .label {
    @extend %has-focus;
  }
}
