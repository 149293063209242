
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











.lang {
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  text-transform: uppercase;

  li:not(:last-of-type) {
    margin-right: 0.25em;

    &::after {
      content: ' /';
    }
  }
}
