
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



















































































































































































.footer {
  @include liquid(padding-top, 40px, 90px);

  position: relative;
  padding-bottom: 4rem;
  font-size: 1.4rem;
  text-transform: uppercase;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
  }
}

.footer__baseline {
  margin-bottom: 5rem;
  font-size: 2rem;
  font-style: italic;
  text-transform: none;

  @include mq($from: xs) {
    font-size: 3.2rem;
  }

  @include mq($from: l) {
    margin-bottom: 6.8rem;
  }
}

.footer__navigation {
  @include mq($from: xs) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 4rem;
  }

  @include mq($from: s) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.footer__navigation__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;

  @include mq($from: xs) {
    margin-bottom: 0;
  }

  &:last-child {
    @include mq(l) {
      align-items: flex-end;
    }
  }
}

.footer__title {
  @include liquid(font-size, 20px, 28px);
  @include liquid(margin-bottom, 20px, 30px);

  text-transform: none;
}

address {
  font-style: normal;
}

::v-deep {
  .footer__link,
  .footer__link.wysiwyg {
    display: inline-block;
    margin-bottom: 2rem;
    font-size: 1.4rem;
    text-decoration: none;
  }

  .lang__item {
    display: inline-block;
    margin-bottom: 2rem;
  }

  .underline {
    --underline-height: 1px;

    background-image: linear-gradient(currentColor, currentColor);
  }
}

.partners {
  display: flex;
  align-items: center;
}

.partners__item {
  margin-bottom: 2rem;

  &:not(:last-of-type) {
    margin-right: 5rem;
  }
}

.legal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  @include mq($from: s) {
    flex-direction: row;
  }

  @include mq($from: l) {
    margin-bottom: 0;
    justify-content: flex-start;
  }
}

.legal__item,
[class*='legal__item'] {
  margin-right: 2rem;
  margin-bottom: 2rem;

  @include mq($from: wrapper) {
    margin-right: 5.6rem;
  }

  &[class*='--epic'] {
    svg {
      fill: currentColor;
      opacity: 0.5;
    }

    &:hover,
    &:focus {
      svg {
        opacity: 1;
      }
    }
  }
}

.credits {
  display: flex;

  @include mq(l) {
    justify-content: flex-end;
  }
}

.credits__entry {
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(:last-of-type) {
    margin-right: 10rem;
  }

  svg,
  img {
    height: 5.2rem;
  }

  a {
    display: block;
  }
}
