.button {
  display: inline-block;
  padding: 1.7rem 3.1rem;
  font-size: 1.8rem;
  line-height: 1;
  text-decoration: none;
  text-transform: capitalize;
  background: transparent;
  border: 1px currentColor solid;
  border-radius: 50px;
  cursor: pointer;

  &:hover,
  &:focus {
    color: $c-text-light;
    text-decoration: none;
    background: $c-surface-dark;
    border-color: transparent;
  }
}
