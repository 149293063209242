.wysiwyg,
[class*='wysiwyg--'] {
  @include liquid('font-size', 18px, 24px);

  line-height: 1.5;

  h1,
  .h1,
  h2,
  .h2 {
    @include fluid(
      margin-bottom,
      (
        xxs: 30px,
        m: 50px,
      )
    );
  }

  h2,
  .h2 {
    @include liquid('font-size', 36px, 50px);
    font-style: italic;
  }

  h3,
  .h3 {
    @include liquid('font-size', 30px, 40px);
  }

  h4,
  .h4 {
    @include liquid('font-size', 22px, 30px);
  }

  h5,
  .h5 {
    @include liquid('font-size', 20px, 28px);

    &:not(:first-child) {
      @include liquid('margin-top', 50px, 120px);
    }

    margin-top: 0;
  }

  h3,
  .h3,
  h4,
  .h4 {
    @include fluid(
      margin-bottom,
      (
        xxs: 20px,
        m: 30px,
      )
    );
  }

  p {
    // prettier-ignore
    @include fluid(margin-bottom, (xxs: 20px, m: 30px));

    &.lead {
      @include liquid('font-size', 24px, 36px);

      line-height: 1.4;

      &:not(:last-child) {
        @include liquid('margin-bottom', 50px, 120px);
      }
    }
  }

  hr {
    // prettier-ignore
    @include fluid(margin-top, (xxs: 20px, m: 50px));
    // prettier-ignore
    @include fluid(margin-bottom, (xxs: 20px, m: 50px));

    height: 2px;
    background: $c-black;
    border: 0;
  }

  ul,
  ol {
    margin-top: 4rem;
    margin-bottom: 6rem;
  }

  ol {
    list-style: none;
    counter-reset: li;
  }

  ul li {
    position: relative;
    padding-bottom: 1.5rem;
    line-height: 1.4em;
  }

  ol li {
    position: relative;
    padding-bottom: 1.5rem;
    counter-increment: li;

    &::before {
      content: counter(li) '. ';
      display: inline-flex;
      margin-right: 1rem;
      color: $c-black;
    }
  }

  a {
    @extend .underline--default;
  }

  .table-wrapper {
    overflow-x: auto;
  }

  table {
    min-width: 76rem;

    thead {
      @include fluid(
        font-size,
        (
          xxs: 9px,
          xl: 11px,
        )
      );

      color: $c-black;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    tbody {
      @include fluid(
        font-size,
        (
          xxs: 15px,
          xl: 18px,
        )
      );

      color: $c-gray-darker;
      line-height: 3.2rem;
    }

    tr {
      border-bottom: 1px solid $c-gray-light;
    }

    td {
      padding: 3rem 0;
    }
  }

  blockquote {
    @include liquid('font-size', 18px, 28px);

    p {
      &:first-child {
        &::before {
          content: '“';
        }
      }

      &:last-child {
        &::after {
          content: '”';
        }
      }
    }
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.wysiwyg.lead {
  p {
    @include liquid(font-size, 24px, 36px);
  }
}
