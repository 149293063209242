
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































.search-form {
  --clip-path: 0%;

  background-color: $c-surface-light;
  clip-path: inset(0 0 var(--clip-path) 0);
}

.search-form__inner {
  display: flex;
  align-items: center;
  height: var(--header-height);
}

.search-form__label {
  margin-right: 2.4rem;
  cursor: pointer;
}

.search-form__label__svg {
  // prettier-ignore
  @include fluid(width, (xs: 20px, l: 32px));

  // prettier-ignore
  @include fluid(height, (xs: 20px, l: 32px));

  display: block;
  stroke: $c-text-dark;
  opacity: 0.4;
}

.search-form__input {
  width: 100%;
  font-size: 2.2rem;
  background-color: transparent;
  border-width: 0;

  &::placeholder {
    color: $c-text-dark;
    opacity: 0.4;
  }
}

.search-form__submit__svg {
  // prettier-ignore
  @include fluid(width, (xs: 20px, l: 32px));

  // prettier-ignore
  @include fluid(height, (xs: 20px, l: 32px));

  display: block;
  stroke: $c-text-dark;
  opacity: 0.4;
  transition: opacity $transition-duration $easing;
}

.search-form__submit {
  margin-left: 2.4rem;

  &:focus,
  &:hover {
    .search-form__submit__svg {
      opacity: 1;
    }
  }
}
