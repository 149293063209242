
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























































.megamenu {
  position: relative;
  flex-grow: 1;
}

a {
  position: relative;
  display: inline-block;
}

.megamenu__nav {
  &:not(:last-of-type) {
    margin-bottom: 4.6rem;

    @include mq($from: m) {
      margin-bottom: 0;
    }
  }
}

.socials {
  @include liquid(font-size, 14px, 16px);

  a {
    line-height: 1.2;
    text-transform: capitalize;
  }

  @include mq($from: m) {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.primary {
  display: flex;
  align-items: center;
  font-size: 3.2rem;
  font-style: italic;
  line-height: 1;

  li {
    margin-bottom: 1.2rem;
  }

  a {
    &:not(:hover, :focus, .router-link-exact-active) {
      color: $c-text-grey;
    }
  }
}

.lang-container {
  @include mq($from: m) {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
