.underline {
  --underline-speed: 350ms;
  --underline-height: 50%;

  text-decoration: none;
  background-image: linear-gradient($c-fluo, $c-fluo);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 0% var(--underline-height);
  transition: color var(--underline-speed) $ease-out-cubic,
    background-size var(--underline-speed) $ease-out-cubic;

  &:hover,
  &:focus,
  &.router-link-exact-active {
    background-position: 0% 100%;
    background-size: 100% var(--underline-height);
    transition-timing-function: $ease-out-cubic;
  }
}

.underline-trigger {
  text-decoration: none;

  &:hover,
  &:focus {
    .underline {
      background-position: 0% 100%;
      background-size: 100% var(--underline-height);
    }
  }
}

.underline--default {
  --underline-speed: 500ms;
  --underline-height: 1px;
  --dash-width: 100px;

  background-image: linear-gradient($c-surface-light, $c-surface-light),
    linear-gradient(currentColor, currentColor),
    linear-gradient(currentColor, currentColor);
  background-size: var(--dash-width) var(--underline-height),
    100% var(--underline-height), 0 var(--underline-height);
  background-position: calc(var(--dash-width) * -1) 100%, 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size var(--underline-speed) $ease-out-cubic,
    background-position var(--underline-speed) $ease-out-cubic;
  text-decoration: none;

  &:hover,
  &:focus {
    background-size: var(--dash-width) var(--underline-height),
      0 var(--underline-height), 100% var(--underline-height);
    background-position: calc(100% + var(--dash-width)) 100%, 100% 100%, 0 100%;
    transition-timing-function: $ease-out-cubic;
    text-decoration: none;
  }
}
