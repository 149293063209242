// Additional wrapper is needed because display: flex does not work in safari
.bullet-list__summary {
  &::-webkit-details-marker,
  &::marker {
    display: none;
  }

  cursor: pointer;
  list-style: none;
  user-select: none;
}

.bullet-list__item {
  @include liquid(font-size, 18px, 24px);

  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 1.3rem;
  text-align: left;
  text-decoration: none;
  padding-bottom: 35px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 1px;
    background-color: currentColor;
  }
}

.bullet-list__item__dot {
  @include liquid(width, 16px, 26px);
  @include liquid(height, 16px, 26px);

  position: relative;
  // flex-shrink: 0;
  // margin-right: 3rem;
  border: 1px currentColor solid;
  border-radius: 50%;

  @include mq($from: m) {
    // margin-right: 20%;
  }
}
