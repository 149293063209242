.cookiebar {
  z-index: 2;
}

.cookiebar-checkbox__input:checked ~ .cookiebar-checkbox__indicator,
.cookiebar-checkbox:hover
  .cookiebar-checkbox__input:not([disabled]):checked
  ~ .cookiebar-checkbox__indicator,
.cookiebar-checkbox__input:checked:focus ~ .cookiebar-checkbox__indicator {
  background-color: $c-surface-dark;
}
