.marquee-cta {
  @include liquid(margin-bottom, 100px, 160px);

  display: block;
  overflow: hidden;
  max-width: 100vw;
  text-decoration: none;
  background: $c-surface-light;

  .marquee-container {
    position: relative;

    &:not(:last-child) {
      @include liquid(margin-bottom, 40px, 80px);
    }
  }

  .marquee {
    display: flex;
    line-height: 1;
    white-space: nowrap;

    &.ltr {
      flex-direction: row-reverse;
    }
  }
}

.marquee-text {
  margin-bottom: 0.25em;
  color: $c-surface-light;
  mix-blend-mode: difference;

  .marquee__item {
    @include liquid(font-size, 50px, 160px);

    display: inline-flex;
    align-items: center;
    font-style: italic;
  }

  .marquee__item__icon {
    width: 0.5em;
    height: 0.5em;
    margin: 0 0.4em;

    svg {
      display: block;
      width: 100%;
      height: 100%;
      stroke: currentColor;
    }
  }
}

.marquee-visuals {
  position: absolute;
  top: 0;

  &.ltr {
    right: 0;
  }

  &:not(.ltr) {
    left: 0;
  }

  .marquee__item {
    display: flex;
    flex-shrink: 0;
  }

  .marquee__visual {
    @include liquid(width, 80px, 200px);
    @include liquid(height, 80px, 200px);

    margin-right: 50vw;
    // margin-right: 10px; // TEMP DEBUG
    object-fit: contain;
  }
}
