
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


















.panel-container {
  --columns: 0.5; // 8 columns
  --scale-x: 1;

  &.large {
    --columns: 0.8125; // 13 columns
  }

  position: fixed;
  z-index: $z-index-panel;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @include mq($from: m) {
    // Left as comment for now because it causes as many issues as it fixes
    // Prevent layout shift by compensating scrollbar disapearance
    // right: $scrollbar-width;
  }

  &::before {
    $thirteen-columns: 0.8125;

    content: '';
    position: fixed;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-color: $c-surface-light;
    transform-origin: 0 0;
    transform: scaleX(var(--scale-x));

    @include mq($from: m) {
      width: calc(
        (100vw - #{$wrapper-padding} * 2) * var(--columns) + #{$wrapper-padding}
      );
    }

    @include mq($from: wrapper) {
      width: calc(
        ((100vw - #{$inner-content-width}) / 2) +
          (#{$inner-content-width} * var(--columns))
      );
    }
  }

  &.right {
    &::before {
      right: 0;
      left: unset;
      transform-origin: 100% 0;
    }

    .panel__outside {
      transform-origin: 100% 0;
    }
  }
}

.panel__outside {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparentize($color: $c-fluo, $amount: 0.2);
  backdrop-filter: blur(3px);
  cursor: pointer;
  transform-origin: 0 0;
}

.pannel__wrapper {
  display: flex;
  padding-right: 0; // align with edge of screen

  @include mq($from: m) {
    padding-right: $wrapper-padding;
  }

  &.right {
    justify-content: flex-end;
  }
}

::v-deep {
  .panel {
    @include liquid('font-size', 14px, 18px);

    position: relative;
    z-index: $z-index-panel;
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    padding-right: 6.25%;
    padding-bottom: 5rem;

    .wysiwyg {
      font-size: inherit;
    }

    .wysiwyg.details__content {
      font-size: 1.8rem;

      h5,
      .h5 {
        font-size: 2.2rem;
        font-weight: 700;

        * {
          font-size: inherit !important;
        }

        &:not(:first-of-type) {
          margin-top: 5rem;
        }
      }
    }

    @include mq($from: m) {
      max-width: 50%; // 8 columns

      &.large {
        max-width: 81.25%; // 13 columns
      }

      &.right {
        padding-right: 0;
        padding-left: 5rem;
      }
    }
  }

  .panel__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: var(--header-height);
    margin-bottom: 5.6rem;
    border-bottom: 1px currentColor solid;
  }

  .panel__header__content {
    display: flex;
    align-items: center;
  }

  .panel__dot {
    @include liquid('width', 16px, 26px);
    @include liquid('height', 16px, 26px);

    margin-right: 1rem;
    border: 1px currentColor solid;
    border-radius: 50%;

    &.yellow:not(.not-yellow) {
      background-color: $c-fluo;
      border-width: 0;
    }
  }

  .panel__header__title {
    @include liquid('font-size', 18px, 22px);

    line-height: 1;
  }

  .panel__header__close-btn {
    display: flex;
    align-items: center;
    margin-left: auto;

    svg {
      width: 3.5rem;
      height: 3.5rem;
    }

    @include mq($from: m) {
      display: none;
    }
  }

  .panel__title {
    @include liquid('font-size', 24px, 30px);

    margin-bottom: 2rem;
  }

  .panel__content {
    @include liquid('margin-bottom', 65px, 72px);

    margin-bottom: 2rem;
  }

  .follow {
    --x: 0;
    --y: 0;

    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    display: none;
    width: 8rem;
    height: 8rem;
    pointer-events: none;
    transform: translate(var(--x), var(--y));

    @include mq($from: m) {
      display: block;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.active {
      .follow__inner {
        opacity: 1;
        transform: rotate(90deg);
      }
    }
  }

  .follow__inner {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px currentColor solid;
    border-radius: 50%;
    transform: rotate(0deg);
    transition: all $transition-duration $easing;
  }
}

.is-moving {
  .panel {
    overflow: hidden;
    padding-right: calc(6.25% + var(--scrollbar-width));
  }
}

.go-back {
  width: 3rem;
  height: 3rem;
  stroke: currentColor;
}

.go-bac__svg {
  width: 100%;
  height: 100%;
}
