
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























































































































































$link-margin-left-default: 3.6rem;

a {
  color: currentColor;
  text-decoration: none;
}

.header__outer {
  --progress: 0;

  position: fixed;
  z-index: $z-index-header;
  top: 0;
  right: 0;
  left: 0;
  background-color: $c-surface-light;
  transition: transform $transition-duration $easing,
    min-height $transition-duration $easing;

  // Hide the "sticky scrolling header" content
  // but allow older safari to show main menu when it's open
  > .wrapper {
    overflow: hidden;
  }

  &.scrolling-down {
    // Do NOT apply transform on header container if menu is opened
    // otherwise it will messes up the children transforms
    &:not(.menu-opened) {
      transform: translateY(-100%);

      &.is-single {
        // If we're displaying a single ressource, do not hide whole header
        // but only it's pirmary component
        transform: translateY(0%);

        .header {
          min-height: var(--secondary-header-height);
        }

        .header__inner--primary {
          transform: translateY(calc(var(--header-height) * -1));
        }

        .header__inner--secondary {
          transform: translateY(0);
        }
      }
    }
  }

  // Fix tricky horizontal clipping issue on primar navigation items
  ::v-deep {
    .pannel__wrapper {
      padding-left: 0;
    }

    .panel {
      padding-left: 6.25%;
    }
  }
}

.header {
  @include liquid('font-size', 18px, 22px);

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: var(--header-height);
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  border-bottom: 1px currentColor solid;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $c-fluo;
    transform: scaleX(var(--progress));
    transform-origin: 0 0;
  }
}

.header__inner,
[class*='header__inner--'] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: transform $transition-duration $easing;

  &[class*='--secondary'] {
    position: absolute;
    transform: translateY(calc(var(--header-height)));
  }
}

.header__go-back {
  display: flex;

  svg {
    stroke-width: 2px;
    stroke: currentColor;
  }
}

.header__inner__title {
  display: none;
  margin-right: auto;
  margin-left: 3.5rem;
  transform: translateY(calc(var(--secondary-header-height)));
  transition: transform $transition-duration $easing;

  &.active {
    transform: translateY(0);
  }

  @include mq($from: $header-breakpoint) {
    display: block;
  }
}

.header__inner__right {
  @include liquid(--link-margin-left, 20px, 32px);

  display: flex;
}

.header__menu-button {
  display: flex;
  align-items: center;

  &:focus:not(:hover) {
    text-decoration: none;

    .underline {
      background-size: 0% var(--underline-height);
    }
  }
}

.header__menu-button__label.underline {
  line-height: 1;
}

.header__menu-button__dot {
  @include liquid(width, 16px, 26px);
  @include liquid(height, 16px, 26px);

  margin-right: 1rem;
  background-color: $c-fluo;
  border-radius: 50%;
}

.header__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4.9rem;
  height: 4.5rem;
  transform: translate(-50%, -50%);

  @include mq($from: $header-breakpoint) {
    width: 6.4rem;
    height: 6rem;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.header__nav {
  display: none;

  @include mq($from: $header-breakpoint) {
    display: block;
  }
}

.header__links-list {
  display: flex;
}

.header__links-list__item {
  &:not(:first-child) {
    margin-left: var(--link-margin-left, $link-margin-left-default);
  }
}

.header__link {
  &:hover,
  &:focus,
  &.router-link-exact-active {
    cursor: pointer;
    text-decoration: none;
  }
}

.underline {
  line-height: 1.4;
}

.search-trigger__svg {
  // prettier-ignore
  @include fluid(width, (xs: 20px, l: 32px));

  // prettier-ignore
  @include fluid(height, (xs: 20px, l: 32px));

  display: block;
}

.search-trigger {
  margin-left: var(--link-margin-left, $link-margin-left-default);
  padding: 0 5px;
  transition: transform $transition-duration $easing;
  transform: translateY(0%);

  &.close {
    position: absolute;
    top: 100%;
    right: 0;

    .search-trigger__svg {
      transform: scale(1.7);
    }
  }
}

.search-trigger-wrapper {
  position: relative;
  display: flex;
  overflow: hidden;

  &.open {
    .search-trigger {
      transform: translateY(-100%);
    }
  }
}

.search-overlay {
  position: fixed;
  // +2px because we want to see the bottom border
  top: calc(var(--header-height) + 2px);
  left: 0;
  width: 100%;
  height: 100%;

  ::v-deep {
    .search-form__inner {
      /* stylelint-disable-next-line selector-max-combinators, selector-max-compound-selectors */
      .no-scroll & {
        padding-right: var(--scrollbar-width);
      }
    }
  }

  @include mq($from: m) {
    top: 0;
  }
}

.search-overlay__background {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparentize($c-fluo, 0.3);
  backdrop-filter: blur(3px);
  transform-origin: 0 0;
}

.follow-mouse {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
