h1,
.h1 {
  @include liquid('font-size', 56px, 120px);

  font-style: italic;
  font-weight: 400;
  line-height: 1.3;
}

h2,
.h2 {
  @include liquid('font-size', 34px, 92px);

  font-style: italic;
  font-weight: 400;
  line-height: 1.3;
}

h3,
.h3 {
  @include liquid('font-size', 36px, 50px);

  font-style: italic;
  font-weight: 400;
  line-height: 1.3;
}

h4,
.h4 {
  @include liquid('font-size', 18px, 28px);

  font-weight: 400;
  line-height: 1.3;
}

h5,
.h5 {
  @include liquid('font-size', 16px, 24px);

  font-weight: 400;
  line-height: 1.3;
}

h6,
.h6 {
  @include liquid('font-size', 18px, 22px);

  font-weight: 400;
  line-height: 1.3;
}

// H7 ? 🤨

.serif {
  font-family: $ff-alt;
  font-size: 1.15em; // Alt FF is slightly smaller than body font, this roughly bring it back up so it matches the design
  font-style: normal; // Fix firefox "superitalic" issue
  line-height: 1;
}

.subtitle {
  @include liquid('font-size', 12px, 14px);

  text-transform: uppercase;
}

.lead {
  @include liquid('font-size', 24px, 36px);

  line-height: 1.4;
}

.small {
  @include liquid('font-size', 12px, 14px);
}

::selection {
  background: $c-fluo;
}
