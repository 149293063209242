.input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.input {
  position: absolute;
  opacity: 0;

  &:checked {
    + .label,
    + .label:hover,
    + .label:focus {
      .dummy-input::after {
        transform: translateX(0%);
      }
    }
  }

  &:hover:not(:checked),
  &:focus:not(:checked) {
    + .label {
      .dummy-input::after {
        transform: translateX(-50%);
      }
    }
  }
}

.label {
  @include liquid('font-size', 12px, 14px);

  display: flex;
  align-items: center;
  line-height: 1;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    .dummy-input::after {
      transform: translateX(-50%);
    }
  }
}

.dummy-input {
  @include liquid('width', 13px, 18px);
  @include liquid('height', 13px, 18px);
  @include liquid('margin-right', 8px, 12px);

  position: relative;
  overflow: hidden;
  clip-path: circle(
    50% at 50% 50%
  ); // Fix visible overflow during transform on safari
  border: 1px currentColor solid;
  border-radius: 50%;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: currentColor;
    border-radius: 50%;
    transform: translateX(-100%);
    transition: transform $transition-duration $easing;
  }
}
