.page {
  overflow-x: hidden;
  // Setting the background fix a bug on chrome where the blurry overlay does not not affect the content properly
  background-color: $c-surface-light;
}

.page__title,
[class*='page__title'] {
  @include liquid(margin-top, 50px, 160px);

  display: inline-block; // Set parent height
  width: 100%;
  text-align: center;
}

.page__intro {
  @include liquid(margin-bottom, 50px, 100px);
}

.page__section,
[class*='page__section--'] {
  @include liquid(padding-bottom, 100px, 160px);

  padding-top: 2.4rem;
  border-top: 1px currentColor solid;

  &[class*='--no-pb'] {
    padding-bottom: 0;
  }
}

.page__section__title {
  @include liquid(margin-bottom, 50px, 100px);

  font-size: 1.4rem;
  font-style: normal;
  text-transform: uppercase;
}
